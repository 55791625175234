// Use these constants to reuse existing cloudinary sizes where possible.
// To reduce duplicated storage costs in cloudinary + increase reuse of existing cached images/sizes.

// Naming conventions {ImageType}_{Size}
// Sizes: Small, Standard, Large

export const ImageResizePropertyTile = 'q_auto:eco,f_auto,w_340,h_255';
export const ImageResizePropertyTileStandard = 'q_auto:eco,f_auto,w_530,h_400,c_fill,g_center,fl_progressive';
export const ImageResizePropertyCarouselMobile = 'q_auto:eco,f_auto,w_530,h_400,c_fill,g_center,fl_progressive';
export const ImageResizePropertyCarouselDesktop = 'q_auto:eco,f_auto,w_900,h_600,c_limit';
export const ImageResizePropertyCarouselFull = 'q_auto:good,f_auto,w_1200,h_900,c_limit';
export const ImageResizeAgentReviewSelfie = 'q_auto:good,f_auto,w_800,h_500,c_fill,g_auto';
export const ImageResizeCampaignThumbnail = 'q_auto:good,f_auto,w_120,h_80,c_fill,g_auto';

// Using c_pad to ensure the entire floor plan is visible using 1DPR
export const FloorPlanGallery = 'q_auto:eco,f_auto,w_423,h_240,c_pad';
export const FloorPlanModal = 'q_auto:eco,f_auto,w_768,h_1024,c_pad';

export const FancyReviewTileImage = 'q_auto:eco,f_auto,h_372,c_limit/w_352,h_372,c_crop';

export const AvatarStandard = 'q_auto:best,f_auto,w_200,h_200,c_limit,c_pad';
export const AvatarLandscape = 'q_auto:best,f_auto,w_288,h_216,c_crop,c_fill,g_face';

export const ProfileBanner = {
  Standard: 'q_auto:eco,f_auto,w_810,h_455,c_fill,g_center,fl_progressive,c_pad,b_white',
  Logo: 'q_auto:good,f_auto,h_80,c_limit',
};

import { Pipe, PipeTransform } from '@angular/core';
import { RmaSrcsetItem } from '@rma/generic/domain/types';
import { ImageOptimiseService } from './image-optimise.service';

@Pipe({
  name: 'imageSrcsetOptimise',
  standalone: true,
})
export class ImageSrcsetOptimisePipe implements PipeTransform {
  constructor(private readonly imageOptimiseService: ImageOptimiseService) {}

  transform(value: string, config: RmaSrcsetItem[]): string {
    return this.imageOptimiseService.getSrcSetFromCdn(value, config);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ImageOptimiseService } from './image-optimise.service';

@Pipe({
  name: 'imageOptimise',
  standalone: true,
})
export class ImageOptimisePipe implements PipeTransform {
  constructor(private readonly imageOptimiseService: ImageOptimiseService) {}
  // maybe two sets of params -> enum and custom styles?
  transform(value: string, params = 'q_auto:eco,f_auto'): string {
    return this.imageOptimiseService.getFromCdn(value, params);
  }
}

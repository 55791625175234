import { Injectable } from '@angular/core';
import { RmaSrcsetItem } from '@rma/generic/domain/types';
import { Environment } from '@rma/generic/util/environment';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class ImageOptimiseService {
  constructor(
    private readonly featureProviderService: FeatureProviderService,
    private readonly environment: Environment,
  ) {}
  // maybe two sets of params -> enum and custom styles?
  public getFromCdn(value: string, params = 'q_auto:eco,f_auto'): string {
    const { cdnUrl, cloudinaryBase, cloudinarySuffix } = this.environment.assets;
    const isCdn = value?.includes(cdnUrl);
    if (this.featureProviderService.getFeature(FeatureFlags.DisableCloudinary, false) || typeof value !== 'string' || !value || !isCdn) {
      return value;
    }
    return value.replace(cdnUrl, `${cloudinaryBase}${params}${cloudinarySuffix}`);
  }

  public getSrcSetFromCdn(value: string, config: RmaSrcsetItem[]): string {
    return config.map((i) => `${this.getFromCdn(value, i.optimisation)} ${i.width}`).join();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Environment } from '@rma/generic/util/environment';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';

@Pipe({
  name: 'imageCacheOptimise',
  standalone: true,
})
export class ImageCacheOptimisePipe implements PipeTransform {
  constructor(
    private readonly featureProviderService: FeatureProviderService,
    private readonly environment: Environment,
  ) {}

  // maybe two sets of params -> enum and custom styles?
  transform(value: string, params = 'q_auto:eco,f_auto', cache = 'cl'): string {
    const { cdnUrl, cloudinaryBase, cloudinarySuffix } = this.environment.assets;

    const isCdn = value?.includes(cdnUrl);

    if (this.featureProviderService.getFeature(FeatureFlags.DisableCloudinary, false) || typeof value !== 'string' || !value || !isCdn) {
      return value;
    }

    if (this.featureProviderService.getFeature$(FeatureFlags.EnableImageCaching, false)) {
      //If the param is not supported by RMAs image cache, it will explode an Nick Lau will cry
      const supportedParams = [
        'q_auto:eco,f_auto,w_200,h_200,c_limit,c_pad',
        'q_auto:eco,f_auto,w_340,h_255',
        'q_auto:eco,f_auto,w_530,h_400,c_fill,g_center,fl_progressive',
        'q_auto:eco,f_auto,w_900,h_600,c_limit',
      ];

      const useRmaImage = supportedParams.includes(params);

      const rmaCachePolicy = useRmaImage ? `/${cache}` : '';

      return value.replace(cdnUrl, `${cloudinaryBase}${params}${rmaCachePolicy}${cloudinarySuffix}`);
    }

    return value.replace(cdnUrl, `${cloudinaryBase}${params}${cloudinarySuffix}`);
  }
}
